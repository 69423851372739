import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import Link from "next/link";
import { KeyTextField } from "@prismicio/types";
import { LogomarkWithNameBlue } from "@/assets/vectors/logo";

/**
 * Props for `LinksSlice`.
 */
export type LinksSliceProps = SliceComponentProps<Content.LinksSliceSlice>;

/**
 * Component for "LinksSlice" Slices.
 */
const LinksSlice = ({ slice }: LinksSliceProps): JSX.Element => (
    <section
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        className="flex w-full flex-col gap-y-8 px-4 text-center lg:max-w-[1056px] lg:flex-row lg:items-start lg:justify-between lg:p-0 lg:text-start"
    >
        <div className="hidden lg:contents">
            <LogomarkWithNameBlue />
        </div>
        <LinkSection
            title={slice.primary.title_business}
            links={slice.primary.links_business}
        />
        <LinkSection
            title={slice.primary.title_courtsite}
            links={slice.primary.links_courtsite}
        />
        <LinkSection
            title={slice.primary.title_support}
            links={slice.primary.links_support}
        />
        <LinkSection
            title={slice.primary.title_legal}
            links={slice.primary.links_legal}
        />
    </section>
);

const LinkSection = ({
    title,
    links,
}: {
    title: KeyTextField;
    links: Content.LinksSliceSliceDefaultPrimaryLinksBusinessItem[];
}): JSX.Element => (
    <div className="flex flex-col gap-4">
        <div className="font-bold text-blue-grey-900">{title}</div>
        <div className="typography-sub flex flex-col gap-3">
            {links.map((i) => {
                if (!("url" in i.path) || typeof window === "undefined")
                    return <></>;
                const child = (
                    <div className="cursor-pointer text-blue-grey-300">
                        {i.name}
                    </div>
                );
                if (!i.path.url) return <div key={i.name}>{child}</div>;

                const link = new URL(i.path.url ?? "", window.location.origin);
                const isSameSite = link.host === window.location.host;

                if (isSameSite)
                    return (
                        <Link key={i.name} href={link}>
                            <a className="remove-styles-a">{child}</a>
                        </Link>
                    );
                return (
                    <a key={i.name} href={i.path.url} target="_blank">
                        {child}
                    </a>
                );
            })}
        </div>
    </div>
);

export default LinksSlice;
